<template>
  <page-content :show-search="false">

    <template #default v-if="view_permission">
      <edit-field-header
        :title="title"
        :show-back-btn="false"
      />
      <b-row>
        <b-col cols="12" md="9" lg="7" class="d-flex">
          <b-form-file
            ref="file-input"
            id="upload-file"
            accept=".xlsx, .xls"
            v-model="file"
            :placeholder="$t('batchupload.placeholder')"
            :drop-placeholder="$t('batchupload.drop_placeholder')"
            :browse-text="$t('batchupload.browse')"
          />
          <b-button
            variant="info"
            @click="downloadSampleFile"
            class="flex-shrink-0 ml-1"
          >
            {{ $t('batchupload.download_sample_file') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
          <b-col cols="12" md="9" lg="7">
            <b-button
              variant="primary"
              @click="submit"
              :disabled="!file"
            >
              {{ $t('batchupload.upload') }}
            </b-button>
            <b-button
              variant="secondary"
              @click="clearFile"
              :disabled="!file"
              class="ml-1"
            >
              {{ $t('common.clear') }}
            </b-button>
          </b-col>
        </b-row>
    </template>
    <template v-else #default class="text-center">{{$t('permission.no_view')}}</template>

  </page-content>
</template>

<script>
import common from '@/common'
import PageContent from '@/components/PageContent'
// import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import EditFieldHeader from '@/components/EditFieldHeader'

export default {
  components: {
    PageContent,
    TableHeader,
    // TableContent,
    EditFieldHeader,
  },
  data() {
    return {
      title: common.getMenuName('bedexitbatchupload'),
      file: null,
      sampleFileUrl: '',
      view_permission: common.checkPermission('Mon_Batch_Upload'),
    }
  },
  mounted() {
  },

  methods: {
    submit() {
      console.log('common batch')
      common.apiBatchUpload({
        file: this.file,
        clearFile: this.clearFile,
        api: '/batch/BatchBEMonitors',
      })
    },
    downloadSampleFile() {
      if (this.sampleFileUrl !== '') {
        window.open(common.getServerUrl() + this.sampleFileUrl)
      } else {
        const that = this
        common.apiGetData('/batch/GetBEMonitorSamplePath')
            .then((res) => {
              that.sampleFileUrl = res.data.path
              window.open(common.getServerUrl() + res.data.path)
            })
      }
    },
    clearFile() {
      this.$refs['file-input'].reset()
    }
  }
}
</script>
